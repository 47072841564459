<!--<div class="w-full flex justify-content-center p-8">-->
<!--  <span class="text-5xl">Ta funkcjonalność wkrótce zostanie dodania</span>-->
<!--</div>-->
<div class="col-9 flex justify-content-center flex-column m-auto mb-7">
  <div class="flex flex-column">
    <span>Data zakończenia aukcji:</span>
    <p-calendar
      [(ngModel)]="tomorrow"
      [showTime]="true"
      [minDate]="minDate"
      [stepMinute]="1"
      [firstDayOfWeek]="1"
      [dateFormat]="'dd.mm.yy'"
      [hideOnDateTimeSelect]="false"
      (onSelect)="selectDateFunction($event)"
    ></p-calendar>
  </div>
  <p-fileUpload
    class="pt-2 w-full"
    [files]="myFile"
    [name]="'auctionUpload'"
    [multiple]="true"
    [accept]="acceptTypeFile"
    [disabled]="false"
    invalidFileLimitMessageDetail="Dozwolony jest upload jedynie dwóch plików na raz"
    invalidFileTypeMessageDetail="Zły format pliku, obsługiwane są jedynie {{acceptTypeFile}}"
    cancelLabel="Anuluj"
    uploadLabel="Wyślij"
    chooseLabel="Wybierz pliki"
    mode="advanced"
    [customUpload]="true"
    (uploadHandler)="auctionUpload($event)"
  >
  </p-fileUpload>
  <p-dialog header="Wiadomość" [modal]="true" [draggable]="false" [resizable]="false" [(visible)]="display">
    {{ dialogContent }}
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="display=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>
</div>
