import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import {ComAPIService} from "../../../API/com-api.service";
import {ToastrService} from "ngx-toastr";
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-numer-validation',
  templateUrl: './numer-validation.component.html',
  providers: [DialogService],
})
export class NumerValidationComponent implements OnInit {
  form: any;
  show = false;
  loadingButton = false;
  codeForm = new UntypedFormGroup({
    code: new UntypedFormControl(null, [
      Validators.required,
    ])
  })

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private serwis: ComAPIService,
    private toastr: ToastrService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.codeForm.controls;
  }
  ngOnInit(): void {
    this.form = this?.config?.data?.form;
    // console.log(this.form, 'this.user');
  }

  showOfferts($event: any) {
    console.log("$event", $event)
  }

  onSubmit() {
    if (this.codeForm.invalid) {
      this.codeForm.markAllAsTouched();
      this.codeForm.markAsDirty();
    } else {
      this.verifyCodeSMS()
      this.changeDetector.detectChanges()
    }
  }

  verifyCodeSMS() {
    this.loadingButton = true;
    this.serwis.smsCodeVerify({code: this.codeForm.value.code, phoneNumber: this.form.phone})
      .subscribe(item => {
          if(item?.error) {
            this.toastr.success(item.error);
            this.loadingButton = false;
            this.register();
            this.ref.close();
          }
        },
        (error)=> {
          this.loadingButton = false;
          this.toastr.error(error.error.error);
          console.log(error.error.error)
        });
  }

  register() {
    this.serwis
      .register(this.form)
      .subscribe(item => {
        if(item?.error) {
          this.toastr.success(item.error);
          this.router.navigate([`/`]);
        }
      },
        (error)=> {
          this.toastr.error(error.error.error);
          console.log(error.error.error)
        });
  }
}
