import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsDateOlderPipe } from './is-date-older.pipe';

@NgModule({
  declarations: [IsDateOlderPipe],
  imports: [CommonModule],
  exports: [IsDateOlderPipe],
})
export class PipeModule {}
