import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AddCockieInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.method === 'GET') {
      const authReq = request.clone({
        withCredentials: true,
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Max-Age': '3600',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
        }),
      });
      return next.handle(authReq);
    } else {
      const authReq = request.clone({
        withCredentials: true,
      });
      return next.handle(authReq);
    }
  }
}
