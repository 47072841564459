<section class="container">
  <h3 class="container-title">
    <span class="container-title-black">Swiss</span> Car
  </h3>
  <div class="container-contact">
    <div class="container-contact-item">
      <img
        class="container-contact-icon"
        src="../../assets/images/telephone.svg"
        alt="telephone icon"
        loading="lazy"
      />
      <a class="container-title-tel" href="tel: +48 733 666 696"
        >+48 733 666 696</a
      >
    </div>
    <div class="container-contact-item">
      <img
        class="container-contact-icon"
        src="../../assets/images/mail.svg"
        alt="mail icon"
        loading="lazy"
      />
      <a class="container-title-mail" href="mailto: kontakt@swiss-car.pl"
        >kontakt@swiss-car.pl</a
      >
    </div>
  </div>
</section>
<section class="copyright">
  <p class="copyright-text">
    Copyright {{year}} swiss-car.pl | Designed and Created by MardDev
  </p>
</section>
