 <section class="login-container">
  <h1 class="login-container-title">Zaloguj Się</h1>
  <section class="form-section">
    <form [formGroup]="loginForm" class="col-12 flex justify-content-center align-items-center flex-column">
      <div
        class="col-10 xl:col-7 flex flex-column align-items-start p-2">
        <label class="mr-2" for="login">Login:</label>
        <input
          [ngClass]="{'ng-invalid ng-dirty': f['login'].dirty || f['login'].touched}"
          required
          class="w-full"
          id="login"
          [formControlName]="'login'"
          type="login"
          pInputText
          placeholder="Służy do logowania"
        >
        <ng-container *ngIf="f['login']?.errors && (f['login'].dirty || f['login'].touched)">
          <small
            *ngIf="f['login']?.errors['required']"
            class="p-error block"
          >Login jest wymagany</small>
        </ng-container>
      </div>

      <div class="col-10 xl:col-7 flex flex-column align-items-start p-2">
        <label class="mb-1" for="password">Hasło</label>
        <input
          [ngClass]="{'ng-invalid ng-dirty': f['password'].dirty || f['password'].touched}"
          required
          autocomplete="off"
          pInputText
          class="w-full"
          type="password"
          id="password"
          placeholder="Musi mieć od 8 do 20 znaków"
          [formControlName]="'password'">
        <ng-container *ngIf="f['password']?.errors && (f['password'].dirty || f['password'].touched)">
          <small
            *ngIf="f['password']?.errors['required']"
            class="p-error block"
          >Hasło jest wymagane</small>
          <small
            *ngIf="f['password']?.errors['matching'] && f['password'].value.length > 7"
            class="p-error block"
          >Podane hasła nie są identyczne</small>
          <small
            *ngIf="f['password']?.errors['minlength']"
            class="p-error block"
          >Podane hasło musi mieć minimum 8 znaków</small>
          <small
            *ngIf="f['password']?.errors['maxlength']"
            class="p-error block"
          >Podane hasło może mieć maksymalnie 20 znaków</small>
        </ng-container>
      </div>

      <p-button (onClick)="tryLogin()" [loading]="loading" type="submit" [disabled]="!loginForm.valid" label="Zaloguj się" class="p-2 col-10 xl:col-7" styleClass=" w-full p-button-danger form-button"></p-button>

      <div class="col-10 xl:col-7 flex justify-content-between align-items-center remember-forget-password">
        <mat-checkbox class="remember-me" formControlName="rememberMe">Zapamiętaj mnie</mat-checkbox>
        <a class="forget-password" href="./przypomnij-haslo">Zapomniałem hasła</a>
      </div>
    </form>
  </section>
 </section>
