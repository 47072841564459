import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterPageComponent } from './register-page.component';
import { ButtonModule } from 'primeng/button';
import { RouterModule, Routes } from '@angular/router';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { ReactiveFormsModule } from "@angular/forms";

const routes: Routes = [
  {
    path: '',
    component: RegisterPageComponent,
    title: 'Swiss-Car | Rejestracja',
  },
];

@NgModule({
  declarations: [RegisterPageComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ButtonModule,
    InputMaskModule,
    InputTextModule,
    ReactiveFormsModule
  ]
})
export class RegisterPageModule {}
