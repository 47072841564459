import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./page-not-found.component";

const routes: Routes = [
  {
    path: '',
    component: PageNotFoundComponent,
    title: 'Swiss-Car | Nie znaleziono strony',
  },
];

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ]
})
export class PageNotFoundModule { }
