import {
  ChangeDetectorRef,
  Component, Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ComAPIService } from '../API/com-api.service';
import {isPlatformBrowser} from "@angular/common";
import {finalize, interval} from "rxjs";
import {devURL} from "../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  user = localStorage.getItem('user');
  activated = '';
  isAdmin: boolean;
  href: string = '';

  constructor(
    private service: ComAPIService,
    private ref: ChangeDetectorRef,
    private router: Router,

    @Inject(PLATFORM_ID) private platformId: Object
) {

  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.service.isAdmin()
        .subscribe((value) => {
        // console.log('isAdmin', value.isAdmin)
        this.isAdmin = value.isAdmin;
      });
    }
  }

  logout() {
    this.service.logout('Wylogowano pomyślnie').subscribe();
  }

  active(item: string) {
    this.activated = item;
  }
}
