<section class="container">
  <h1 class="text-6xl m-auto text-center mb-3">Moje konto</h1>
  <p class="text-xl text-center">
    Witaj <b>{{ user }}</b
    >. Teraz możesz sprawdzić aktualne aukcje lub dokonać zmiany ustawień
    swojego konta
  </p>
  <div class="card grid col-12 md:col-9 m-auto surface-100 p-5 border-round-md">
    <a [routerLink]="['./moje-aukcje']" class="col-12 md:col-6 no-underline">
      <button
        pButton
        pRipple
        class="surface-0 h-4rem w-full text-base border-round-md border-white"
        routerLinkActive="active-link"
      >
        <span class="w-full text-base text-center text-color">Moje Aukcje</span>
      </button>
    </a>
    <a
      [routerLink]="['./moje-ustawienia']"
      class="col-12 md:col-6 no-underline"
    >
      <button
        pButton
        class="surface-0 h-4rem w-full text-base border-round-md border-white"
        routerLinkActive="active-link"
      >
        <span class="w-full text-base text-center text-color"
          >Moje Ustawienia</span
        >
      </button>
    </a>
    <ng-container *ngIf="isAdmin">
      <a
        [routerLink]="['./all-users']"
        class="col-12 md:col-6 no-underline text-color"
      >
        <button
          pButton
          class="surface-0 h-4rem w-full text-base border-round-md border-white"
          routerLinkActive="active-link"
        >
          <span class="w-full text-base text-center text-color"
            >Lista użytkowników</span
          >
        </button>
      </a>
      <a [routerLink]="['./all-auctions']" class="col-12 md:col-6 no-underline">
        <button
          pButton
          class="surface-0 h-4rem w-full text-base border-round-md border-white"
          routerLinkActive="active-link"
        >
          <span class="w-full text-base text-center text-color"
            >Lista wszystkich aukcji</span
          >
        </button>
      </a>
      <a [routerLink]="['./all-bids']" class="col-12 md:col-6 no-underline">
        <button
          pButton
          class="surface-0 h-4rem w-full text-base border-round-md border-white"
          routerLinkActive="active-link"
        >
          <span class="w-full text-base text-center text-color"
          >Wszystkie Oferty</span
          >
        </button>
      </a>
      <a [routerLink]="['./add-auction']" class="col-12 md:col-6 no-underline">
        <button
          pButton
          class="surface-0 h-4rem w-full text-base border-round-md border-white"
          routerLinkActive="active-link"
        >
          <span class="w-full text-base text-center text-color"
            >Dodaj aukcję</span
          >
        </button>
      </a>

      <a [routerLink]="['./my-scripts']" class="col-12 md:col-6 no-underline">
        <button
          pButton
          class="surface-0 h-4rem w-full text-base border-round-md border-white"
          routerLinkActive="active-link"
        >
          <span class="w-full text-base text-center text-color"
            >Moje skrypty</span>
        </button>
      </a>
      <a [routerLink]="['./my-autoBets']" class="col-12 md:col-6 no-underline">
        <button
          pButton
          pRipple
          class="surface-0 h-4rem w-full text-base border-round-md border-white"
          routerLinkActive="active-link"
        >
          <span class="w-full text-base text-center text-color">Automatyczne licytacje</span>
        </button>
      </a>
      <a [routerLink]="['./all-access']" class="col-12 md:col-6 no-underline">
        <button
          pButton
          class="surface-0 h-4rem w-full text-base border-round-md border-white"
          routerLinkActive="active-link"
        >
          <span class="w-full text-base text-center text-color"
          >Wszystkie dane</span>
        </button>
      </a>
      <a [routerLink]="['./record-scripts']" class="col-12 md:col-6 no-underline">
        <button
          pButton
          class="surface-0 h-4rem w-full text-base border-round-md border-white"
          routerLinkActive="active-link"
        >
          <span class="w-full text-base text-center text-color"
          >Nagrania automatów</span>
        </button>
      </a>
    </ng-container>
    <a class="col-12 md:col-6 no-underline h-4rem" routerLink="moje-konto">
      <button
        pButton
        class="surface-0 h-4rem w-full text-base border-round-md border-white"
        (click)="logout()"
        routerLinkActive="active-link"
      >
        <span class="text-base text-center w-full text-color">Wyloguj</span>
      </button>
    </a>
  </div>
</section>
<router-outlet></router-outlet>
