import { Component, OnInit } from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ComAPIService } from '../API/com-api.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginForm = new UntypedFormGroup({
    login: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    rememberMe: new UntypedFormControl(''),
  });
  loading = false;

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  constructor(
    private service: ComAPIService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  tryLogin() {
    this.loading = true;
    this.service
      .login(
        this.loginForm.value.login as string,
        this.loginForm.value.password as string
      )
      .subscribe((item) => {
          if (item.error) {
            this.toastr.error(item.error);
          } else {
            this.successFullLogin()
          }
          this.loading = false;
        },
        (error) => {
          this.toastr.error(error.error.message);
          this.loading = false;
        });
    if (
      this.service.login(
        this.loginForm.value.login as string,
        this.loginForm.value.password as string
      )
    ) {
    } else {
    }
    this.loginForm.reset();
  }

  successFullLogin() {
    let redirectTo = '/moje-konto'
    const params = this.route.snapshot.queryParams['returnURL'];
    // console.log('params', params)
    if (params !== undefined && params !== '/moje-konto/moje-konto' && params !== '/logowanie') {
      redirectTo = params
    }
    // console.log('redirectTo', redirectTo)

    this.router.navigate([redirectTo]);
    this.toastr.success('Zalogowano pomyślnie');

  }
}
