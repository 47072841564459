import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import {catchError, EMPTY, Observable, of, tap, throwError} from 'rxjs';
import { ComAPIService } from './com-api.service';
import {Router} from "@angular/router";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private service: ComAPIService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((err) => {
          if (err.status === 403 || err.status === 401) {
            return this.service.logout('Twoja sesja wygasła. Zaloguj się ponownie.')
          } else {
            return throwError(() => err);
          }
        })
      )

  }
}
