<div class="col-12 md:col-9 m-auto">
  <p-table
    [value]="autoBets"
    [responsiveLayout]="'scroll'"
    [loading]="loading"
    [autoLayout]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [rowHover]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center">Nazwa</th>
        <th class="text-center">Status</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-autoBet>
      <tr>
        <td class="text-center">{{ autoBet.name }}</td>
        <td class="text-center"><p-toggleButton [(ngModel)]="autoBet.status" (click)="changeAutoBetsStatus(autoBet)" onLabel="Włączony" offLabel="Wyłączony"></p-toggleButton>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
