import { Component, OnInit } from '@angular/core';
import {ComAPIService} from "../../API/com-api.service";
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-add-auction',
  templateUrl: './add-auction.component.html',
  styleUrls: ['./add-auction.component.scss']
})
export class AddAuctionComponent implements OnInit {
  myFile: File[] = [];
  acceptTypeFile: string = '.pdf, .rar, application/octet-stream, application/zip, application/x-zip, application/x-zip-compressed, .zip, .7zip';
  minDate: Date = new Date()
  tomorrow : Date = new Date(this.minDate);
  pl: any;
  display: boolean = false;
  dialogContent: string;
  fileLimit: number = 2;
  constructor(
    private service: ComAPIService,
    private config: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    this.config.setTranslation({
      monthNames: ["Styczeń","Luty","Marzec","Kwiecień","Maj","Czerwiec","Lipiec","Sierpień","Wrzesień","Październik","Listopad","Grudzień"],
      monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze","Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
      dayNamesMin: ["Nd","Pn","Wt","Śr","Czw","Pt","Sob"],
      dayNamesShort: ["Niedz", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"],

      reject: 'Cancel',
      //translations
    });

    // this.translateService.setDefaultLang('pl')
   this.setMinimumDate();

    this.tomorrow.setDate(this.tomorrow.getDate())
    this.tomorrow.setMinutes(0)
  }

  setMinimumDate() {
    this.minDate.setDate(new Date().getDate() - 2)
    this.minDate.setMinutes(23)
    this.minDate.setHours(59)
  }

  auctionUpload($event: any) {
    this.service.uploadFile($event.files, this.tomorrow).subscribe({
      next: answer => {
        this.fileLimit = this.fileLimit + 2
        this.myFile = [];
        this.setMinimumDate();
        this.showDialog(answer.message);
    },
      error: error => {
        this.fileLimit += this.fileLimit + 1
        this.showDialog(error.error.error);
      }}
    )
  };

  selectFile($event: any) {
    console.log('selectFile', this.myFile, $event);
  }

  showDialog(dialogContent: string) {
    this.dialogContent = dialogContent;
    this.display = true;
  }

  selectDateFunction($event: any) {
    this.tomorrow.setMinutes(0)
  }
}
