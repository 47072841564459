import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from "./home-page.component";
import { RouterModule, Routes } from "@angular/router";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from "primeng/tooltip";
import { SampleAuctionsModule } from "../sample-auctions/sample-auctions.module";
import { HowToBuyComponent } from "../how-to-buy/how-to-buy.component";

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    title: 'Swiss-Car',
  }
]

@NgModule({
  declarations: [HomePageComponent, HowToBuyComponent],
  imports: [
    CommonModule, RouterModule.forChild(routes), ProgressSpinnerModule, ButtonModule,SampleAuctionsModule, TooltipModule
  ]
})
export class HomePageModule { }
