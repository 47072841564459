<div class="container">
  <h3 class="container-title">Jak kupować</h3>
  <p class="container-desc">Instrukcja krok po kroku</p>
</div>
<div class="cards-list">
  <section class="card">
    <p class="card-text"><span class="card-number">1</span><br>Zarejestruj sie na naszej stronie</p>
  </section>
  <section class="card">
    <p class="card-text"><span class="card-number">2</span><br>Wybierz interesującą aukcję</p>
  </section>
  <section class="card">
    <p class="card-text"><span class="card-number">3</span><br>Zalicytuj wybrany pojazd</p>
  </section>
  <section class="card">
    <p class="card-text"><span class="card-number">4</span><br>My w Twoim imieniu złożymy ofertę na platformie ubezpieczalni</p>
  </section>
  <section class="card">
    <p class="card-text"><span class="card-number">5</span><br>Jeżeli Twoja oferta wygra, natychmiast poinformujemy Cię o tym</p>
  </section>
</div>
