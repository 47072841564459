<div #myDiv>
  <p-card class="section section--full">
    <p-table
      [value]="access"
      [scrollable]="true"
      [rowHover]="true"
      [autoLayout]="true"
      [resizableColumns]="true"
      [loading]="loadingTable"
      [sortField]="'key'"
      dataKey="access_id"
      editMode="row"
    >
      <ng-template pTemplate="caption">
        <div class="table-header flex justify-content-between">
          Lista wszystkich nagrań w formacie serwis-marka-model-id_aukcji-nazwa_użytkownika-kwota`
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width:33%" [pSortableColumn]="'key'">
            Nazwa nagrania
            <p-sortIcon [field]="'key'"></p-sortIcon>
          </th>
          <th style="width:33%">
            Data Nagrania
          </th>
          <th style="width:33%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-videos>
        <tr>
          <td>
            {{ videos?.path }}
          </td>
          <td>
            {{ videos?.ctimeMs | date : 'short' }}
          </td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button pButton pRipple type="button" icon="pi pi-play" (click)="onPlayRecord(videos)" class="p-button-rounded p-button-text"></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template [pTemplate]="'emptymessage'">
        <tr [class]="'empty-data'">
          <td colspan="10" class="h-30rem flex justify-content-center">
            Brak wyników do wyświetlenia
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="rowPerPage"
      [rows]="visibleRows"
      [showCurrentPageReport]="true"
      [currentPageReportTemplate]="
        'Showing {first} to {last} of {totalRecords} entries'
      "
     ></p-paginator>
  </p-card>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-toast></p-toast>
