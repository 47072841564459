<div #myDiv>
  <p-card class="section section--full">
    <p-table
      [value]="users"
      [paginator]="true"
      [scrollable]="true"
      [rowHover]="true"
      [autoLayout]="true"
      [resizableColumns]="true"
      [showCurrentPageReport]="true"
      [sortField]="'user_id'"
      [sortOrder]="1"
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="rowPerPage"
      [rows]="visibleRows"
      [loading]="loadingTable"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]="['user_id', 'created_on', 'last_login', 'activ']"
    >
      <ng-template pTemplate="caption">
        <div class="table-header flex justify-content-between">
          Lista wszystkich użytkowników
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'user_id'">
            Id użytkownika
            <p-sortIcon [field]="'user_id'"></p-sortIcon>
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'userId'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po ID'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('userId')"
                  ></button>
                  <!--              <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"-->
                  <!--                      (click)="acceptFilter()"-->
                  <!--              ></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Nick
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'nickname'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po nicku'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('nickname')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Imię
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'name'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po imieniu'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('name')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Nazwisko
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'surname'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po nazwisku'"
                />
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('surname')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th [pSortableColumn]="'created_on'">
            Założone konto
            <p-sortIcon [field]="'created_on'"></p-sortIcon>
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                Od
                <p-calendar
                  [formControlName]="'createDateStart'"
                  [placeholder]="'Szukaj po dacie założenia'"
                  selectionMode="single"
                  [readonlyInput]="true"
                  [dateFormat]="'dd/mm/yy'"
                  inputId="single"
                >
                  ></p-calendar
                >
                Do
                <p-calendar
                  [formControlName]="'createDateEnd'"
                  [placeholder]="'Szukaj po dacie założenia'"
                  [minDate]="formFilter.controls['createDateStart'].value"
                  [dateFormat]="'dd/mm/yy'"
                  selectionMode="single"
                  [readonlyInput]="true"
                  inputId="single"
                >
                  ></p-calendar
                >
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('createDateStart', 'createDateEnd')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th [pSortableColumn]="'last_login'">
            Ostatnie logowanie
            <p-sortIcon [field]="'last_login'"></p-sortIcon>
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                Od
                <p-calendar
                  [formControlName]="'loginDateStart'"
                  [placeholder]="'Szukaj po zalogowaniu'"
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                </p-calendar>
                Do
                <p-calendar
                  [formControlName]="'loginDateEnd'"
                  [placeholder]="'Szukaj po zalogowaniu'"
                  [minDate]="formFilter.controls['loginDateStart'].value"
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                  ></p-calendar
                >
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('loginDateStart', 'loginDateEnd')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Numer telefonu
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'phone'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po telefonie'"
                />
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('phone')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Adres email
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'email'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po emailu'"
                />
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('email')"
                  ></button>
                  <!--              <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>Typ użytkownika</th>
          <th [pSortableColumn]="'activ'">
            Status konta
            <p-sortIcon [field]="'activ'"></p-sortIcon>
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <p-selectButton
                  [formControlName]="'activ'"
                  [options]="stateOptions"
                  optionLabel="label"
                  optionValue="value"
                ></p-selectButton>
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('activ')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th [pSortableColumn]="'specialUser'">
            Automatyczne Licytacje
            <p-sortIcon [field]="'specialUser'"></p-sortIcon>
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <p-selectButton
                  [formControlName]="'specialUser'"
                  [options]="stateOptions"
                  optionLabel="label"
                  optionValue="value"
                ></p-selectButton>
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('specialUser')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>Opcje</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{ user.user_id }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.surname }}</td>
          <td>{{ user.created_on | date: "dd/MM/yy, HH:mm" }}</td>
          <td>{{ user.last_login | date: "dd/MM/yy, HH:mm" }}</td>
          <td>{{ user.phone }}</td>
          <td>
            {{
              user.email.length > 10
                ? (user.email | slice: 0:10) + ".."
                : user.email
            }}
          </td>
          <td>{{ user.account_roles[0]?.roles.role_name }}</td>
          <td>{{ user.activ === false ? "Nieaktywne" : "Aktywne" }}</td>
          <td><p-toggleButton [(ngModel)]="user.specialUser" (click)="changeSpecialUserStatus(user)" onLabel="Tak" offLabel="Nie"></p-toggleButton>
          </td>

          <td>
            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteSelectedUser(user)"></button>
            <button pButton pRipple icon="pi pi-user-edit" class="p-button-rounded p-button-warning" (click)="changeUserActive(user)"></button>

          </td>
        </tr>
      </ng-template>
      <ng-template [pTemplate]="'emptymessage'">
        <tr [class]="'empty-data'">
          <td colspan="10" class="h-30rem flex justify-content-center">
            Brak wyników do wyświetlenia
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
