<div class="password-remember flex justify-content-center align-items-center flex-column p-3">
  <h1 class="w-full text-center">Nie pamiętasz hasła ?</h1>
  <div class="flex justify-content-center align-items-center flex-column surface-100 border-round-sm p-6">
    <ng-container *ngIf="!messageContent else ContentAfterSend">
      <p class="w-full">Wyślemy do Ciebie wiadomość e-mail z instrukcją resetowania hasła.</p>
      <div class="field w-full">
        <label for="email" class="hidden">Email</label>
        <input id="email" [formControl]="resetAddressEmail" type="email" aria-describedby="email-help" class="p-inputtext-lg w-full" placeholder="Adres e-mail"  pInputText />
        <small *ngIf="resetAddressEmail.invalid && (resetAddressEmail.dirty || resetAddressEmail.touched) && resetAddressEmail.value.length === 0" id="email-help1" class="p-error block">Adres e-mail jest wymagany.</small>
        <small *ngIf="resetAddressEmail.invalid && (resetAddressEmail.dirty || resetAddressEmail.touched) && resetAddressEmail.value.length > 0" id="email-help2" class="p-error block">Adres e-mail nie jest poprawny.</small>
      </div>
      <button (click)="sendResetEmail()" class="button-bg-red w-full text-center p-2 cursor-pointer">
        <span class="text-center">Wyślij wiadomość email</span>
      </button>
    </ng-container>
    <ng-template #ContentAfterSend>
      <p class="w-full text-2xl">Wiadomość została wysłana na podany adres e-mail.</p>
    </ng-template>
  </div>
</div>
