import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDateOlder',
})
export class IsDateOlderPipe implements PipeTransform {
  transform(value: any, ...args: any[]): boolean {
    return new Date(value) > new Date();
  }
}
