<div class="auction-container relative">
  <button
    *ngIf="isAdmin"
    id="deleteAuctionButton"
    pButton
    pRipple
    icon="pi pi-trash"
    class="p-button-rounded p-button-warning absolute right-0 z-3"
    (click)="deleteSelectedAuction(auction)"
  ></button>
  <div #image class="image flex align-items-center justify-content-center">
    <a
      [ngClass]="{ hidden: gallerySpinner }"
      class="preview-image z-2"
      [routerLink]="openAuction"
    >
      <img
        loading="lazy"
        class="preview-image"
        [src]="imageSRC"
        alt="Car"
        (error)="imageLoaded($event)"
      />
    </a>
    <span *ngIf="gallerySpinner">Ładowanie zdjęcia...</span>
  </div>
  <div class="preview-desc">
    <h2
      class="preview-title"
      pTooltip="{{ auction?.mark }} {{ auction?.model }}"
      tooltipPosition="top"
      tooltipEvent="focus"
      [life]="5000"
      (click)="showTooltip()"
    >
      {{ auction?.mark }} {{ auction?.model }}
    </h2>
    <ul class="preview-list">
      <li class="preview-list-item">
        <p>
          Serwis: <span>{{ auction?.serwis }}</span>
        </p>
      </li>
      <li class="preview-list-item">
        <p>
          Przebieg:
          <span>{{
            auction?.mileage
              ? (auction?.mileage | number : "2.") + " km"
              : "brak danych"
          }}</span>
        </p>
      </li>
      <li class="preview-list-item preview-list-item-desktop">
        <p>
          Pierwsza rejestracja: <span>{{ first_register }}</span>
        </p>
      </li>
      <li class="preview-list-item preview-list-item-mobile">
        <p>
          1 rejestracja: <span>{{ first_register }}</span>
        </p>
      </li>
      <ng-container
        *ngIf="
          auction?.serwis === 'Axa' &&
          auction?.actual_price_serwis &&
          auction?.last_check_price
        "
      >
        <li class="preview-list-item">
          <p>
            Aktualna cena w serwisie:
            <span>{{ +auction?.actual_price_serwis | number }} CHF</span>
          </p>
        </li>
        <li class="preview-list-item">
          <p>
            Ostatnia aktualizacja:
            <span>{{
              auction?.last_check_price | date : "dd.MM.yy, HH:mm"
            }}</span>
          </p>
        </li>
      </ng-container>
      <li class="preview-list-item">
        <p>
          Zakończenie aukcji: <span>{{ endAuction }}</span>
        </p>
      </li>
    </ul>
    <ul class="preview-list-myAuction">
      <li class="preview-list-myAuction-item">
        <p>Your bid:</p>
        <p>7 134 CHF</p>
      </li>
      <li class="preview-list-myAuction-item">
        <p>Winning bid</p>
        <p>7 134 CHF</p>
      </li>
      <li class="preview-list-myAuction-item">
        <p>Place</p>
        <p>1</p>
      </li>
      <li class="preview-list-myAuction-item">
        <p>End date:</p>
        <p>2022-01-06 16:56:05</p>
      </li>
    </ul>
    <div class="timer flex justify-content-center">
      <ng-container *ngIf="showTimer; else showEndAuction">
        <ul class="timer-list">
          <li *ngIf="showDaysTimer" class="timer-list-item">
            <p class="timer-list-item-number">{{ days }}</p>
            <p class="timer-list-item-text">dni</p>
          </li>
          <li class="timer-list-item">
            <p class="timer-list-item-number">{{ hours }}</p>
            <p class="timer-list-item-text">godzin</p>
          </li>
          <li class="timer-list-item">
            <p class="timer-list-item-number">{{ minutes }}</p>
            <p class="timer-list-item-text">minut</p>
          </li>
          <li *ngIf="showSecondsTimer" class="timer-list-item">
            <p class="timer-list-item-number">{{ seconds }}</p>
            <p class="timer-list-item-text">sekund</p>
          </li>
        </ul>
      </ng-container>
      <ng-template
        #showEndAuction
        class="w-full flex justify-content-center p-8 text-5xl"
      >
        <span class="text-center">AUKCJA ZOSTAŁA ZAKOŃCZONA</span>
      </ng-template>
    </div>
    <a class="cursor-pointer no-underline" [routerLink]="openAuction">
      <button class="button block cursor-pointer">
        <p class="button-text">Więcej</p>
      </button>
    </a>
  </div>
</div>
<p-confirmDialog
  header="Zatwierdź"
  [style]="{ width: '50vw' }"
  [baseZIndex]="20000"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
