import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComAPIService } from '../API/com-api.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-user-active',
  templateUrl: './user-active.component.html',
  styleUrls: ['./user-active.component.scss'],
})
export class UserActiveComponent implements OnInit {
  hash: string;

  constructor(
    private router: ActivatedRoute,
    private service: ComAPIService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.hash = this.router.snapshot.paramMap.get('hash') || '';
    console.log(this.hash);
    this.service
      .userActive(this.hash)
      .pipe(take(1))
      .subscribe(() => {
        return setTimeout(() => this.route.navigate(['/logowanie']), 10000);
      });
  }
}
