import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from "./login-page.component";
import { RouterModule, Routes } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";

const routes: Routes = [
  {
    path: '',
    component: LoginPageComponent,
    title: 'Swiss-Car | Logowanie',
  },
];

@NgModule({
  declarations: [LoginPageComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ButtonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    InputTextModule
  ]
})
export class LoginPageModule { }
