export const devURL = {
  production: true,
  login: 'api/login',
  register: 'api/register',
  authSMS: 'api/authSMS',
  smsCodeVerify: 'api/codeverifysms',
  isAdmin: 'api/isAdmin',
  isLogin: 'api/isLogin',
  logout: 'api/logout',
  activeAuction: 'api/auctions/active',
  auctionDetails: 'api/auctions/details',
  settings: 'api/settings',
  marks: 'api/auctions/marks',
  models: 'api/auctions/models',
  bestAuctions: 'api/bestAuctions',
  auctionBid: 'api/auction/bid',
  userDelete: 'api/user/delete',
  resetPassword: 'api/reset-password',
  setNewPassword: 'api/set-new-password',
  userStatus: 'api/user/status',
  specialUserStatus: 'api/specialUser/status',
  auctionDelete: 'api/auction/delete',
  allUsers: 'api/users',
  allBids: 'api/bids',
  allAccess: 'api/access',
  recordScripts: 'api/recordScripts',
  updateAllAccess: 'api/accessUpdate',
  deleteAccess: 'api/accessDelete',
  allAuctions: 'api/auctions',
  myAuctions: 'api/my-auctions',
  activeUser: 'api/user/active',
  scripts: 'api/scripts',
  autoBets: 'api/autoBets',
  autoBetsStatus: 'api/autoBets/status',
  scrapper: 'api/scrapper',
  upload: 'api/upload',
  headless: true,
  DOMAIN_ADRESS: 'https://swiss-car.pl', //musi być https:// bez / na końcu np. https://swiss-car.pl
  TOKEN_KEY: '9z$C&F)J@NcRfUjXn2r4u7x!A%D*G-Ka',
  DATABASE_URL:
    'postgresql://server300634_swiss-car:Frajer11@pgsql14.server300634.nazwa.pl:5432/server300634_swiss-car',
  IMAGE_ADRESS: '/../../../images',
  VIDEO_ADRESS: '/../../../videos'
};
