import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MyAccountComponent } from "./my-account.component";
import { MyAuctionsComponent } from "./my-auctions/my-auctions.component";
import { AllUserComponent } from "./all-user/all-user.component";
import { AllAuctionsComponent } from "./all-auctions/all-auctions.component";
import { AddAuctionComponent } from "./add-auction/add-auction.component";
import { MyScriptsComponent } from "./my-scripts/my-scripts.component";
import { AllBidsComponent } from "./all-bids/all-bids.component";
import { AuthGuardGuard } from "../auth-guard.guard";
import { RegisterPageComponent } from "../register-page/register-page.component";
import { AllAccessComponent } from "./all-access/all-access.component";
import { MyAutoBetsComponent } from "./my-autoBets/my-autoBets.component";
import { RecordScriptsComponent } from "./record-scripts/record-scripts.component";

const mySettingsRouters: Routes = [
  {
    path: 'moje-konto',
    component: MyAccountComponent,
    title: 'Swiss-Car | Moje konto',
    children: [
      {
        path: 'moje-ustawienia',
        component: RegisterPageComponent,
        title: 'Swiss-Car | Moje ustawienia',
      },
      {
        path: 'moje-aukcje',
        component: MyAuctionsComponent,
        title: 'Swiss-Car | Moje aukcje',
      },
      {
        path: 'all-users',
        component: AllUserComponent,
        title: 'Swiss-Car | Wszyscy użytkownicy',
      },
      {
        path: 'all-auctions',
        component: AllAuctionsComponent,
        title: 'Swiss-Car | Wszystkie aukcje',
      },
      {
        path: 'all-bids',
        component: AllBidsComponent,
        title: 'Swiss-Car | Wszystkie oferty',
      },
      {
        path: 'add-auction',
        component: AddAuctionComponent,
        title: 'Swiss-Car | Dodaj aukcje',
      },
      {
        path: 'my-scripts',
        component: MyScriptsComponent,
        title: 'Swiss-Car | Skrypty',
      },
      {
        path: 'my-autoBets',
        component: MyAutoBetsComponent,
        title: 'Swiss-Car | Automatyczne licytajce',
      },
      {
        path: 'all-access',
        component: AllAccessComponent,
        title: 'Swiss-Car | Dostępy',
      },
      {
        path: 'record-scripts',
        component: RecordScriptsComponent,
        title: 'Swiss-Car | Nagrania z automatów',
      },
    ],
    canActivate: [AuthGuardGuard],
  },

  //{ path: '**', component: PageNotFoundComponent }, // for 404
];
@NgModule({
  imports: [RouterModule.forChild(mySettingsRouters)],
  exports: [RouterModule],
})
export class MySettingsRouters {}
