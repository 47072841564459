import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SampleAuctionsComponent } from "./sample-auctions.component";
import { PreviewAuctionModule } from "../preview-auction/preview-auction.module";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: '',
    component: SampleAuctionsComponent,
    title: 'Swiss-Car',
  },
];
@NgModule({
  declarations: [SampleAuctionsComponent],
  exports: [
    SampleAuctionsComponent
  ],
  imports: [
    // RouterModule.forChild(routes),
    CommonModule,
    PreviewAuctionModule,
    ProgressSpinnerModule
  ]
})
export class SampleAuctionsModule { }
