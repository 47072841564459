import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from "@angular/core";
import { ComAPIService } from "../../API/com-api.service";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ConfirmationService, MessageService } from "primeng/api";
import { isPlatformBrowser } from "@angular/common";
import { Table } from "primeng/table";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ShowVideoComponent } from "./show-video/show-video.component";

@Component({
  selector: 'app-record-scripts',
  templateUrl: './record-scripts.component.html',
  styleUrls: ['./record-scripts.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class RecordScriptsComponent implements OnInit, OnDestroy {
  access: any[] = [];
  totalRecords: number = 0;
  rowPerPage = [50, 100, 150, 300];
  visibleRows = 50;
  loadingTable = true;
  formFilter$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: ComAPIService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private ref: ChangeDetectorRef,
    private messageService: MessageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  dynamicDialogRef: DynamicDialogRef;

  @ViewChild(Table, { read: Table }) pTable: Table;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;

  clonedProducts: { [s: string]: any } = {};

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadingTable = true;
      this.getRecordScriptsData();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getRecordScriptsData() {
    this.service
      .getRecordScriptsData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.access = value;
        this.totalRecords = this.access.length;
        this.loadingTable = false;
        this.ref.detectChanges();
      });
  }



  onPlayRecord(video: any) {
    this.dynamicDialogRef = this.dialogService.open(ShowVideoComponent, {
      data: {
        video: video,
      },
      header: 'Nagranie',
      width: 'w-11'
    })
  }
}
