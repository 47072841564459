import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeModule } from './pipe/pipe.module';
import { ValidatorsModuleModule } from './validators/validators-module.module';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import {RippleModule} from "primeng/ripple";
import {ButtonModule} from "primeng/button";
import {AuctionInfoComponent} from "./components/auction-info/auction-info.component";
import {NumerValidationComponent} from "./components/numer-validation/numer-validation.component";
import {ReactiveFormsModule} from "@angular/forms";
import {InputMaskModule} from "primeng/inputmask";

@NgModule({
    declarations: [UserInfoComponent, AuctionInfoComponent, NumerValidationComponent],
    imports: [
        PipeModule,
        ValidatorsModuleModule,
        CommonModule,
        TableModule,
        DynamicDialogModule,
        RippleModule,
        ButtonModule,
        ReactiveFormsModule,
        InputMaskModule,
    ]
})
export class SharedModule {}
