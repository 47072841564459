import { inject, NgModule } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  PreloadAllModules,
  ResolveFn,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from "@angular/router";

import { HowToBuyComponent } from './how-to-buy/how-to-buy.component';
import { AuthGuardGuard } from './auth-guard.guard';
import { UserActiveComponent } from './user-active/user-active.component';
import { BrowserModule } from '@angular/platform-browser';
import { ComAPIService } from "./API/com-api.service";

export const aukcjeResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(ComAPIService).getActiveAuctions(JSON.parse(sessionStorage.getItem("searchOptions")) ? JSON.parse(sessionStorage.getItem("searchOptions")) : {"textFilter":"","quickFilter":"ended"});
  };

export const aukcjeDetailsResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(ComAPIService).getAuctionDetails(route.paramMap.get('id')!);
  };
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./home-page/home-page.module').then((x) => x.HomePageModule),
  },
  {
    path: 'logowanie',
    loadChildren: () =>
      import('./login-page/login-page.module').then((x) => x.LoginPageModule),
  },
  {
    path: 'rejestracja',
    loadChildren: () =>
      import('./register-page/register-page.module').then((x) => x.RegisterPageModule),
  },
  { path: 'jak-kupic', component: HowToBuyComponent, title: 'Swiss-Car | Jak Kupować' },
  { path: 'active/:hash', component: UserActiveComponent },
  {
    path: 'przypomnij-haslo',
    loadChildren: () =>
      import('./remember-password/remember-password.module').then((x) => x.RememberPasswordModule),
  },
  {
    path: 'change-password/:hash',
    loadChildren: () =>
      import('./set-new-password/set-new-password.module').then((x) => x.SetNewPasswordModule),
  },
  {
    path: 'aukcje',
    loadChildren: () =>
      import('./auctions-list/auctions-list.module').then((x) => x.AuctionsListModule),
    resolve: {auctions: aukcjeResolver},
  },
  {
    path: 'aukcja/:id',
    loadChildren: () =>
      import('./detail-auction/detail-auction.module').then((x) => x.DetailAuctionModule),
    resolve: {auctionDetails: aukcjeDetailsResolver},

  },
  {
    path: 'moje-konto',
    title: 'Swiss-Car | Moje Konto',
    children: [
      {
        path: '',
        loadChildren: () =>
          import(`./my-account/my-account.module`).then(
            (m) => m.MySettingsModule
          ),
      },
    ],
    canActivate: [AuthGuardGuard],
  },
  {
    path: '404',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then((x) => x.PageNotFoundModule),
  }, // for 404

  { path: '**', redirectTo: '/404', pathMatch: 'full' }, // for 404
];
@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
