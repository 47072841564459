import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-auction-info',
  templateUrl: './auction-info.component.html',
  providers: [DialogService],
})
export class AuctionInfoComponent implements OnInit {
  auctionData: any = [];
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.auctionData.push(this?.config?.data?.user_data);

    console.log(this.auctionData, 'this.aucction');
  }
}
