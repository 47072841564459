import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  providers: [DialogService],
})
export class UserInfoComponent implements OnInit {
  userData: any = [];
  show = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.userData.push(this?.config?.data?.user_data);

    console.log(this.userData, 'this.user');
    console.log(this.userData.user_id);
  }

  showOfferts($event: any) {
    console.log("$event", $event)

  }
}
