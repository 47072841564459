import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { PreviewAuctionComponent } from "./preview-auction.component";
import { TooltipModule } from "primeng/tooltip";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { ConfirmDialogModule } from "primeng/confirmdialog";



@NgModule({
  declarations: [PreviewAuctionComponent],
  exports: [
    PreviewAuctionComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    RouterModule,
    ButtonModule,
    RippleModule,
    ConfirmDialogModule,
    NgOptimizedImage
  ]
})
export class PreviewAuctionModule { }
