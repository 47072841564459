import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from "@angular/core";
import {
  catchError,
  debounceTime,
  forkJoin,
  map,
  of,
  pairwise,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';
import { ComAPIService } from '../API/com-api.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Paginator } from 'primeng/paginator';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-auctions-list',
  templateUrl: './auctions-list.component.html',
  styleUrls: ['./auctions-list.component.scss'],
})
export class AuctionsListComponent implements OnInit, OnDestroy {
  auctions: any;
  restwertboerseLength: number;
  axaLength:number;
  allianzLength: number;
  swissCrashCars: number;
  isAdmin = false

  page = 0;
  searchForm = new UntypedFormGroup({
    textFilter: new UntypedFormControl(''),
    quickFilter: new UntypedFormControl('ended'),
    selectedMark: new UntypedFormControl(),
    selectedModel: new UntypedFormControl(),
    serwis: new UntypedFormControl(),
    yearsStart: new UntypedFormControl(),
    yearsEnd: new UntypedFormControl(),
  });
  spinner = true;
  options: any;
  positionY: 0;

  markList: any[] = [];
  modelList: any[] = [];
  yearsStart: string[] = [];
  yearsEnd: string[] = [];
  serwisList: string[] = ['Axa', 'Allianz', 'Restwertboerse', 'SwissCrashCars'];
  showSpecialSettings = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  throttle = 300;
  howManyAuctionsToShow = 50;

  @ViewChild('paginator') paginator: Paginator;
  constructor(
    private service: ComAPIService,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if(sessionStorage.getItem("searchOptions")) {
      this.searchForm.patchValue(JSON.parse(sessionStorage.getItem("searchOptions")))
    } else {
      this.searchForm.patchValue({
        ...this.route.snapshot.queryParams,
        selectedMark:
          typeof this.route.snapshot.queryParams['selectedMark'] === 'string'
            ? this.route.snapshot.queryParams['selectedMark']?.split(' ')
            : this.route.snapshot.queryParams['selectedMark'],
        serwis:
          typeof this.route.snapshot.queryParams['serwis'] === 'string'
            ? this.route.snapshot.queryParams['serwis']?.split(' ')
            : this.route.snapshot.queryParams['serwis'],
      });
    }
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: this.searchForm.value,
    });
    this.route.data.subscribe(
      ({auctions}) => {
        this.auctions = auctions;
        this.lengthAuction()
      });
    this.service.isAdmin().pipe(takeUntil(this.destroy$)).subscribe(answer => this.isAdmin = answer.isAdmin)
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.howManyAuctionsToShow = sessionStorage.getItem('howManyAuctionsToShow') ? +sessionStorage.getItem('howManyAuctionsToShow') : 50;
      this.dynamicAddYears();
      this.updateFilters(this.searchForm.value, true);
      this.searchForm.valueChanges
        .pipe(
          debounceTime(500),
          startWith({}),
          pairwise(),
          map(([prevValue, nextValue]) => {
            return nextValue;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((item: any) => {
          sessionStorage.setItem("searchOptions", JSON.stringify(this.searchForm.value));
          this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: this.searchForm.value,
          });
          this.updateFilters(item,false);
        });
    }
  }

  updateFilters(filters: any, firstRun:boolean) {
    this.spinner = true;
    let getAuctions$;
    const getAllModels$ = this.service.getAllModels(filters).pipe(
      catchError(() => {
        return of(null);
      }),
      takeUntil(this.destroy$)
    );
    if (!firstRun) {
      getAuctions$ = this.service.getActiveAuctions(filters).pipe(
        catchError(() => {
          return of(null);
        }),
        takeUntil(this.destroy$)
      );
    } else {
      getAuctions$ = of([]).pipe( catchError(() => {
          return of(null);
        }),
        takeUntil(this.destroy$));
    }
    const getAllMarks$ = this.service.getAllMarks(filters).pipe(
      catchError(() => {
        return of(null);
      }),
      takeUntil(this.destroy$)
    );
    forkJoin([getAuctions$, getAllMarks$, getAllModels$])
      .pipe(takeUntil(this.destroy$))
      .subscribe((results: any) => {
        if(!firstRun) {
          this.auctions = results[0];
        }
        this.markList = results[1];
        this.modelList = results[2];
        this.spinner = false;
        this.lengthAuction();
        if (sessionStorage.getItem('positionY')) {
          setTimeout(() => {
            window.scroll({
              top: +sessionStorage.getItem('positionY'),
              left: 0,
              behavior: 'smooth',
            });
            sessionStorage.removeItem('positionY');
          }, 1000);
        }
      });
  }

  dynamicAddYears() {
    let currentYear = new Date().getFullYear();
    let earliestYear = 1970;
    while (currentYear >= earliestYear) {
      this.yearsStart.push(String(currentYear));
      this.yearsEnd.push(String(currentYear));
      currentYear -= 1;
    }
  }

  trackItem(index: number, auction: any) {
    return auction ? auction.auction_id : null;
  }

  lengthAuction() {
    this.restwertboerseLength = this.auctions.filter((auction:any) => auction.serwis === 'Restwertboerse').length
    this.axaLength = this.auctions.filter((auction:any) => auction.serwis === 'Axa').length
    this.allianzLength = this.auctions.filter((auction:any) => auction.serwis === 'Allianz').length
    this.swissCrashCars = this.auctions.filter((auction:any) => auction.serwis === 'SwissCrashCars').length
  }
  ngOnDestroy() {
    sessionStorage.setItem('positionY', window?.scrollY?.toString());
    sessionStorage.setItem('howManyAuctionsToShow', this.howManyAuctionsToShow.toString());
    sessionStorage.setItem("searchOptions", JSON.stringify(this.searchForm.value));
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onScrollDown() {
    this.howManyAuctionsToShow += 20;
    // console.log('howManyAuctionsToShow', this.howManyAuctionsToShow)
  }

  onScrollUp() {
    this.howManyAuctionsToShow -= 20;
    // console.log('howManyAuctionsToShow', this.howManyAuctionsToShow)
  }
}
