import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ComAPIService } from '../../API/com-api.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { isPlatformBrowser } from '@angular/common';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-all-bids',
  templateUrl: './all-access.component.html',
  styleUrls: ['./all-access.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class AllAccessComponent implements OnInit, OnDestroy {
  access: any[] = [];
  totalRecords: number = 0;
  rowPerPage = [50, 100, 150, 300];
  visibleRows = 50;
  loadingTable = true;
  formFilter$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: ComAPIService,
    private confirmationService: ConfirmationService,
    private ref: ChangeDetectorRef,
    private messageService: MessageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  @ViewChild(Table, { read: Table }) pTable: Table;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;

  clonedProducts: { [s: string]: any } = {};

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadingTable = true;
      this.getAllAccessData();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getAllAccessData() {
    this.service
      .getAllAccessData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.access = value;
        this.totalRecords = this.access.length;
        this.loadingTable = false;
        this.ref.detectChanges();
      });
  }

  onRowEditInit(access: any) {
    this.clonedProducts[access.access_id] = { ...access };
    this.ref.detectChanges();
  }

  onRowEditSave(access: any) {
    if (access?.key?.length > 0 && access?.password?.length > 0) {
      this.saveAccess(access);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Pola KLUCZ oraz Wartość nie mogą być puste',
      });
      this.ref.detectChanges();
    }
    // if (access.price > 0) {
    //   delete this.clonedProducts[access.id];
    //   this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
    // }
    // else {
    //   this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid Price'});
    // }
  }

  onRowEditCancel(access: any, index: number) {
    console.log(this.access[index], this.clonedProducts[access.access_id]);
    this.access[index] = this.clonedProducts[access.access_id];
    delete this.clonedProducts[access.key];
    if (!access?.access_id) {
      this.access.splice(index, 1);
    }
    this.ref.detectChanges();
  }

  onAddNewRow() {
    const newP = {
      key: 'key',
      password: 'password',
    };
    this.access.unshift(newP);
    // @ts-ignore
    this.pTable.editingRowKeys[newP[this.pTable.dataKey]] = true;
    this.onRowEditInit(newP);
    this.totalRecords = this.access.length;
    this.ref.detectChanges();
    console.log('this.access', this.access);
  }

  saveAccess(access: any) {
    this.service
      .postAllAccessData(access)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value.status) {
          this.loadingTable = true;
          this.getAllAccessData();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Klucz dodany poprawnie',
          });
        }
        console.log('saveAccess', value);
        this.ref.detectChanges();
      });
  }

  deleteAccess(access: any, index?: number) {
    this.service
      .deleteAccessData(access)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value.status) {
          this.loadingTable = true;
          this.getAllAccessData();
        }
        this.ref.detectChanges();
      });
  }

  onRowDelete(access: any, index: number) {
    console.log(access, index, this.clonedProducts[index]);
    if (access?.access_id) {
      this.confirm(access);
      this.ref.detectChanges();
    } else {
      delete this.clonedProducts[access.key];
      this.ref.detectChanges();
    }
  }
  confirm(access: any) {
    this.confirmationService.confirm({
      message: `Jesteś pewien, że chcesz usunąć ten klucz ${access.key}:${access.password} ?`,
      header: 'Potwierdzenie',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteAccess(access);
      },
      reject: (type: any) => {},
    });
  }
}
