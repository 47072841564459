import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ComAPIService } from '../API/com-api.service';
import {ToastrService} from "ngx-toastr";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {NumerValidationComponent} from "../shared/components/numer-validation/numer-validation.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class RegisterPageComponent implements OnInit, OnDestroy {
  isMineSettings: boolean;

  registerForm = new UntypedFormGroup({
    login: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', Validators.required),
    surname: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', Validators.required),
    phone: new UntypedFormControl('', Validators.required),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(20),
    ]),
    confirmPassword: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(20),
    ]),
  });
  dynamicDialogRef: DynamicDialogRef;
  loadingButton = false;

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }
  constructor(private serwis: ComAPIService,
              private toastr: ToastrService,
              private router: Router,
              public dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.isMineSettings = this.router.url.search('moje-ustawienia') > 0;
    console.log(this.isMineSettings)
    if (this.isMineSettings) {
      this.getMyAccountDetails()
    }
  }

  onSubmit() {
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      this.registerForm.markAsDirty();
      console.log(this.registerForm);
      return;
    } else if (this.registerForm.controls['password'].value !== this.registerForm.controls['confirmPassword'].value) {
      this.toastr.error('Hasła nie są identyczne');
    } else {
      console.log(this.registerForm.value);
      this.loadingButton = true;
      this.serwis
        .authSMS(this.registerForm.value)
        .subscribe(item => {
          if(item?.error) {
            this.toastr.success(item.error);
            this.loadingButton = false;

            this.openDialogUserInfo();
          }
        },
          (error)=> {
            this.loadingButton = false;
            this.toastr.error(error.error.error);
            console.log(error.error.error)
          });
    }
  }
  getMyAccountDetails() {
    this.serwis.getMyUserDetails().subscribe(account => {
      console.log('MyAccountDetails', account)
      this.registerForm.patchValue({
        login: account.username,
        name: account.name,
        surname: account.surname,
        email: account.email,
        phone: account.phone,
        password: '',
        confirmPassword: ''
      })
      this.registerForm.get('login').disable({ emitEvent: false });
      this.registerForm.get('name').disable({ emitEvent: false });
      this.registerForm.get('surname').disable({ emitEvent: false });
      this.registerForm.get('email').disable({ emitEvent: false });
      this.registerForm.get('phone').disable({ emitEvent: false });
      this.registerForm.get('password').disable({ emitEvent: false });
      this.registerForm.get('confirmPassword').disable({ emitEvent: false });

    })
  }
  openDialogUserInfo() {
    this.dynamicDialogRef = this.dialogService.open(NumerValidationComponent, {
      data: {
        form: this.registerForm.value,
      },
      header: 'Podaj kod z wiadomości SMS',
    });
  }

  ngOnDestroy() {
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
  }
}
