import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit, PLATFORM_ID,
} from '@angular/core';
import { ComAPIService } from '../../API/com-api.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  debounceTime,
  Subject,
  Subscription,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-my-auctions',
  templateUrl: './my-auctions.component.html',
  styleUrls: ['./my-auctions.component.scss'],
})
export class MyAuctionsComponent implements OnInit {
  auctions: any;
  totalRecords: number = 0;
  rowPerPage = [50, 100, 150, 300];
  visibleRows = 50;
  loadingTable = true;
  actualDate = new Date().getDate();
  formFilter$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: ComAPIService,
    private ref: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
) {}
  formFilter = new UntypedFormGroup({
    serwis: new UntypedFormControl(''),
    mark: new UntypedFormControl(''),
    model: new UntypedFormControl(''),
    mileageStart: new UntypedFormControl(''),
    mileageEnd: new UntypedFormControl(''),
    gas: new UntypedFormControl(''),
    firstRegisterDateStart: new UntypedFormControl(),
    firstRegisterDateEnd: new UntypedFormControl(),
    VIN: new UntypedFormControl(''),
    auction_webPage_adress: new UntypedFormControl(''),
    endTmeStart: new UntypedFormControl(),
    endTmeEnd: new UntypedFormControl(),
    active: new UntypedFormControl([]),
  });

  stateOptions = [
    { label: 'Aktywne', value: true },
    { label: 'Zakończone', value: false },
  ];
  columnsTable = [
    {name: 'Podgląd Aukcji', code: 'pa'},
    {name: 'Serwis', code: 'serwis'},
    {name: 'Marka', code: 'marka'},
    {name: 'Model', code: 'model'},
    {name: 'Przebieg', code: 'przebieg'},
    {name: 'Rejestracja', code: 'rejestracja'},
    {name: 'VIN', code: 'vin'},
    {name: 'Twoja oferta', code: 'to'},
    {name: 'Miejsce', code: 'miejsce'},
    {name: 'Koniec', code: 'koniec'},
  ];

  selectedOptions: string[] = [];

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      console.log(!!localStorage.getItem('myAuctionsColumns'))
      if (!!localStorage.getItem('myAuctionsColumns')) {
        this.selectedOptions = localStorage.getItem('myAuctionsColumns')?.split(",")
      } else {
        this.selectedOptions = ['pa','marka','model','rejestracja','to','miejsce','koniec']
      }

      this.getMyAuctions(this.formFilter.value);
      this.formFilter$ = this.formFilter.valueChanges
        .pipe(debounceTime(1000), takeUntil(this.destroy$))
        .subscribe((value) => {
          if (
            new Date(value?.endTmeStart) < new Date() &&
            value?.active?.includes(true) &&
            value?.active?.includes(false)
          ) {
            value;
          } else if (
            new Date(value?.endTmeStart) < new Date() &&
            value?.active.includes(true)
          ) {
            value.endTmeStart = new Date().toString();
          }
          this.getMyAuctions(value);
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    localStorage.setItem('myAuctionsColumns', this.selectedOptions?.toString())
  }

  getMyAuctions(filters?: any) {
    this.service
      .getMyAuctions(filters)
      .pipe(
        take(1),
        tap((item) => {
          this.auctions = item;
          this.totalRecords = item?.length;
          this.loadingTable = false;
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  resetFilters(firstFilter: string, secondFilter?: string) {
    // @ts-ignore
    this.formFilter.controls[`${firstFilter}`].reset();
    if (secondFilter) {
      // @ts-ignore
      this.formFilter.controls[`${secondFilter}`].setValue(null);
    }
  }
}
