import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from "@angular/core";
import { ComAPIService } from '../../API/com-api.service';
import { Subject, takeUntil } from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import { ConfirmationService, MessageService } from "primeng/api";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-my-autoBets',
  templateUrl: './my-autoBets.component.html',
  providers: [MessageService, ConfirmationService],
})
export class MyAutoBetsComponent implements OnInit, OnDestroy {
  autoBets: any;
  loading = true;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: ComAPIService,
    private ref: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.updateAutoBets()
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  updateAutoBets() {
    this.service.getAutoBetsInfo().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.autoBets = value;
      this.loading = false;
      this.ref.detectChanges();
    });
  }
  changeAutoBetsStatusRequest(autoBet: any) {
    this.service.changeAUtoBetsStatus(autoBet).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.toastr.success('Automatyczne licytacje zostały zmienione');
    })
  }
  changeAutoBetsStatus(autoBet: any) {
    this.confirmationService.confirm({
      message: !autoBet.status ? `Jesteś pewień, że chcesz wyłączyć skrypt automatycznej licytacj dla strony ${autoBet.name} ?` : `Jesteś pewień, że chcesz włączyć skrypt automatycznej licytacj dla strony ${autoBet.name} ?`,
      header: 'Zatwierdź',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.changeAutoBetsStatusRequest(autoBet)
      },
      reject: () => { console.log('Do nothing')}
    });
    this.ref.detectChanges();
  }
}
