<header [ngClass]="href === '/' ? 'header' : 'header-black'">
  <mat-toolbar class="toolbar" [ngClass]="{ 'white-header': showmenu }">
    <span routerLink="" class="red-text cursor-pointer"
      >Swiss <span class="white-text">Car</span></span
    >
    <button
      class="button-mobile"
      mat-icon-button
      mat-button
      (click)="showMenu()"
      aria-label="icon-button with menu icon"
    >
      <mat-icon class="red-icon">menu</mat-icon>
    </button>
    <nav class="navigation-desktop">
      <ul class="navigation-desktop-list">
        <li class="navigation-desktop-list-item">
          <a routerLink="">
            <p
              [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="border-bottom-3 border-white"
            >
              Strona główna
            </p>
          </a>
        </li>
        <li class="navigation-desktop-list-item">
          <a [routerLink]="['./aukcje']">
            <p routerLinkActive="border-bottom-3 border-white">Aukcje</p>
          </a>
        </li>
        <li class="navigation-desktop-list-item">
          <a routerLink="jak-kupic">
            <p routerLinkActive="border-bottom-3 border-white">Jak kupować</p>
          </a>
        </li>
      </ul>
      <div class="buttons-desktop">
        <a routerLink="rejestracja" *ngIf="!isLogin">
          <button class="button-desktop">
            <p>Rejestracja</p>
          </button>
        </a>
        <a routerLink="logowanie" *ngIf="!isLogin">
          <button class="button-desktop button-desktop-red">
            <p>Zaloguj się</p>
          </button>
        </a>
        <a routerLink="moje-konto" *ngIf="isLogin">
          <button class="button-desktop button-desktop-red">
            <p>Moje konto</p>
          </button>
        </a>
      </div>
    </nav>
  </mat-toolbar>
  <ul
    class="menu"
    [ngClass]="{ 'menu-show': showmenu }"
    (click)="showmenu = false"
  >
    <li [ngClass]="href === '/' ? 'menu-item active' : 'menu-item'">
      <a routerLink="" class="menu-item-text">Strona główna</a>
    </li>
    <li [ngClass]="href === '/auctions' ? 'menu-item active' : 'menu-item'">
      <a routerLink="aukcje" class="menu-item-text">Aukcje</a>
    </li>
    <li [ngClass]="href === '/howToBuy' ? 'menu-item active' : 'menu-item'">
      <a routerLink="jak-kupic" class="menu-item-text">Jak Kupować</a>
    </li>
<!--    <li [ngClass]="href === '/contact' ? 'menu-item active' : 'menu-item'">-->
<!--      <a class="menu-item-text">Kontakt</a>-->
<!--    </li>-->
    <li
      [ngClass]="href === '/register' ? 'menu-item active' : 'menu-item'"
      *ngIf="!isLogin"
    >
      <a routerLink="rejestracja" class="menu-item-text">Rejestracja</a>
    </li>
    <li class="menu-item menu-item-red" *ngIf="!isLogin">
      <a routerLink="logowanie" class="menu-item-text menu-item-red-text"
        >Zaloguj się</a
      >
    </li>
    <li class="menu-item menu-item-red" *ngIf="isLogin">
      <a routerLink="moje-konto" class="menu-item-text menu-item-red-text"
        >Moje konto</a
      >
    </li>
  </ul>
  <div [ngClass]="href === '/' ? 'menu-text-block' : 'menu-text-block-none'">
    <h1 class="menu-text-block-title">TWOJE AUTO ZE SZWAJCARII</h1>
    <p class="menu-text-block-desc">
      Odkryj tysiące aukcji pojazdów ze Szwajcarii w jednym miejscu.<br />
      Przeglądaj i bierz udział w licytacjach. Za darmo.
    </p>
    <button (click)="scrollButton()" class="menu-text-block-button">
      Sprawdź
    </button>
  </div>
</header>
