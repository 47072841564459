import {
  ChangeDetectorRef,
  Component,
  ElementRef, Inject,
  OnDestroy,
  OnInit, PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ComAPIService } from '../../API/com-api.service';
import { debounceTime, Subject, Subscription, takeUntil } from "rxjs";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {ConfirmationService, MessageService} from "primeng/api";
import {ToastrService} from "ngx-toastr";
import {isPlatformBrowser} from "@angular/common";
import { ToggleButtonModule } from 'primeng/togglebutton';
import changeSpecialUserStatus from "../../../../routes/changeSpecialUserStatus";
@Component({
  selector: 'app-all-user',
  templateUrl: './all-user.component.html',
  styleUrls: ['./all-user.component.scss'],
  providers: [MessageService, ConfirmationService],

})
export class AllUserComponent implements OnInit, OnDestroy {
  users: any;
  totalRecords: number = 0;
  rowPerPage = [50, 100, 150, 300];
  visibleRows = 50;
  loadingTable = true;
  formFilter$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private service: ComAPIService,
              private ref: ChangeDetectorRef,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private toastr: ToastrService,
              @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  formFilter = new UntypedFormGroup({
    userId: new UntypedFormControl(''),
    nickname: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    surname: new UntypedFormControl(''),
    createDateStart: new UntypedFormControl(),
    createDateEnd: new UntypedFormControl(),
    loginDateStart: new UntypedFormControl(),
    loginDateEnd: new UntypedFormControl(),
    phone: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    userType: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
  });
  stateOptions = [
    { label: 'Aktywne', value: true },
    { label: 'Nieaktywne', value: false },
  ];
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  show: boolean = true;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getusers(this.formFilter.value);
      this.formFilter$ = this.formFilter.valueChanges
        .pipe(
          debounceTime(1000),
          takeUntil(this.destroy$)
        )
        .subscribe((value) => {
          // console.log('form', value);
          this.getusers(value);
        });
    }
  }

  ngOnDestroy() {
    if (this.formFilter$) return this.formFilter$.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getusers(filters?: any) {
    this.service
      .getAllUser(filters)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        console.log('allUsers', value);
        this.users = value;
        this.totalRecords = value.length;
        this.loadingTable = false;
        this.ref.detectChanges();
      });
  }

  resetFilters(firstFilter: any, secondFilter?: any) {
    // @ts-ignore
    this.formFilter.controls[`${firstFilter}`].reset();
    if (secondFilter) {
      // @ts-ignore
      this.formFilter.controls[`${secondFilter}`].setValue(null);
    }
  }

  deleteUser(userID: any) {
    this.service.deleteUserAccount(userID).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.toastr.success('Użytkownik został usunięty');
    })
  }

  changeUserStatus(user: any) {
    this.service.changeUserStatus(user).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.toastr.success('Status został zmieniony');
    })
  }
  changeSpecialUserStatusRequest(user: any) {
    this.service.changeSpecialUserStatus(user).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.toastr.success('Automatyczne licytacje zostały zmienione');
    })
  }
  deleteSelectedUser(user: any) {
    // console.log('user', user)
    this.confirmationService.confirm({
      message: `Jesteś pewień, że chcesz usunąć tego użytkownika "${user.username} ${user.name} ${user.surname} ${user.email}"`,
      header: 'Zatwierdź',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if(user.account_roles[0]?.roles.role_name === 'Admin') {
          this.toastr.warning('Użytkownik o typie Admin nie może zostać usunięty');
        } else {
          this.users = this.users.filter((val: any) => val !== user);
          this.deleteUser(user.user_id)
        }
      },
      reject: () => { console.log('Do nothing')}
    });
    this.ref.detectChanges();
  }
  changeUserActive(user: any) {
    this.confirmationService.confirm({
      message: `Jesteś pewień, że chcesz zmienić status tego użytkownika z ${user.activ} na ${!user.activ}`,
      header: 'Zatwierdź',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.changeUserStatus(user)
      },
      reject: () => { console.log('Do nothing')}
    });
    this.ref.detectChanges();
  }

  changeSpecialUserStatus(user: any) {
    this.confirmationService.confirm({
      message: !user.specialUser ? `Jesteś pewień, że chcesz uniemożlić użytkownikowi ${user.username} automatyczne licytacje?` : `Jesteś pewień, że chcesz umożlić użytkownikowi ${user.username} automatyczne licytacje?`,
      header: 'Zatwierdź',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.changeSpecialUserStatusRequest(user)
      },
      reject: () => { console.log('Do nothing')}
    });
    this.ref.detectChanges();
  };
}
