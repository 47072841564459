<form
  class="w-full"
  [formGroup]="codeForm"
  (ngSubmit)="onSubmit()"
>
  <div class="w-full mb-3">
    <label class="mb-1 hidden" for="code">Kod</label>
    <p-inputMask
      id="code"
      [ngClass]="{'ng-invalid ng-dirty': f['code'].dirty || f['code'].touched}"
      class="p-inputtext-lg w-full"
      [formControlName]="'code'"
      mask="999 999"
      placeholder="000 000"
      >
    </p-inputMask>
    <ng-container *ngIf="f['code']?.errors && (f['code'].dirty || f['code'].touched)">
      <small
        *ngIf="f['code']?.errors['required']"
        class="p-error block"
      >Kod jest wymagany</small>
      <small
        *ngIf="f['code']?.errors['minlength'] || f['code']?.errors['maxlength']"
        class="p-error block"
      >Podany kod musi mieć 6 znaków</small>
    </ng-container>
  </div>
  <p-button type="submit" [disabled]="codeForm.invalid" styleClass="w-full p-button-danger form-button" label="Potwierdź" [loading]="loadingButton"></p-button>
</form>
