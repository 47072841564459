import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {ComAPIService} from "../API/com-api.service";

@Component({
  selector: 'app-remember-password',
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.scss']
})
export class RememberPasswordComponent implements OnInit {

  constructor(private apiService: ComAPIService) { }
  messageContent = false;
  resetAddressEmail = new UntypedFormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]);

  ngOnInit(): void {
  }

  sendResetEmail() {
    this.apiService.forgotPassword(this.resetAddressEmail.value).subscribe(item => this.messageContent = !!item)
  }
}
