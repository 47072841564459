import { Component } from "@angular/core";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { devURL } from "../../../../environments/environment";

@Component({
  selector: 'app-show-video',
  templateUrl: './show-video.component.html',
})
export class ShowVideoComponent {
  video: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.video = this?.config?.data?.video;

    console.log('this.videos', this.video);
  }

  protected readonly devURL = devURL;
}
