<div id="container" class="container">
  <div class="text-container">
    <h1 class="text-container-title">Znajdź auto dla siebie</h1>
    <p class="text-container-desc">
      Poniżej przedstawiamy Państwu aktualnie dostępne pojazdy wraz z czasem
      pozostałym do ich zakończenia. Aby znaleźć interesujący Państwa pojazd,
      wystarczy przeglądać oferty posuwając się w dół. Aby ułatwić Państwu
      odnalezienie interesujących ofert zamieściliśmy również wyszukiwarkę.
      Wystarczy wpisać do niej dowolny tekst (marka, model, rocznik pojazdu) aby
      pokazały się wszystkie interesujące Państwa oferty. Po zakonczeniu aukcji
      otrzymacie Państwo maila z informacją – czy udało się wygrać daną aukcję.
    </p>
  </div>
  <div class="search-container mb-5">
    <form [formGroup]="searchForm">
      <div class="grid justify-content-between px-2">
        <label class="label" for="text">text</label>
        <input
          class="inputText col-12 md:col-7 mb-2"
          placeholder="Szukaj produktów"
          type="text"
          id="text"
          formControlName="textFilter"
        />

        <label class="label" for="select">select</label>
        <select class="selectInput cursor-pointer col-12 md:col-4 mb-2" id="select" formControlName="quickFilter">
          <option value="ended">Sortowanie: od kończących</option>
          <option value="popular">Sortowanie: od najpopularniejszych</option>
          <option value="newAdded">Sortowanie: od najnowszych</option>
          <option value="youngYear">Sortowanie: od najmłodszych</option>
          <option value="oldYear">Sortowanie: od najstarszych</option>
          <option value="last24h">Dodane w ciągu 24h</option>
        </select>
        <button (click)="showSpecialSettings = !showSpecialSettings" id="showSettings" pButton pRipple type="button" icon="pi pi-cog" loadingIcon="pi pi-cog" class="p-button-outlined p-button-secondary col-12 md:col-1 w-full md:w-3rem mb-2"></button>
      </div>
      <div *ngIf="showSpecialSettings" class="grid justify-content-between fadein animation-duration-500">
        <div class="col-12 md:col-6">
          <p-multiSelect styleClass="col-12 mb-2" class="w-min" [options]="markList" formControlName="selectedMark" placeholder="Marka pojazdu"></p-multiSelect>
          <p-multiSelect styleClass="col-12 mb-2" class="w-min" [options]="modelList" formControlName="selectedModel" placeholder="Model pojazdu" [disabled]="!modelList.length"></p-multiSelect>
          <p-multiSelect styleClass="col-12 mb-2" class="w-min" [options]="serwisList" formControlName="serwis" placeholder="Serwis"></p-multiSelect>
        </div>
        <div class="col-12 md:col-6">
          <p-dropdown styleClass="col-12 mb-2" class="w-min" [options]="yearsStart" formControlName="yearsStart" placeholder="Pierwsza rejestracja od" [showClear]="true"></p-dropdown>
          <p-dropdown styleClass="col-12 mb-2" class="w-min"  [options]="yearsEnd" formControlName="yearsEnd" placeholder="Pierwsza rejestracja do" [showClear]="true"></p-dropdown>
        </div>
      </div>
    </form>
  </div>
  <div class="flex justify-content-center align-items-center text-center" *ngIf="isAdmin">
    Restwertboerse: {{restwertboerseLength}} Axa: {{axaLength}} Allianz: {{allianzLength}} SwissCrashCars: {{swissCrashCars}}
  </div>
  <div id="auction-list"
       class="surface-50 w-full"
       *ngIf="!spinner"
  >
    <div
      class="auction-list-container col-11 m-auto justify-content-center align-items-center grid-auto mb-5"
      *ngIf="(!spinner && auctions.length) else noAuction"
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollUpDistance]="1.5"
      [infiniteScrollThrottle]="150"
      [fromRoot]="true"
      (scrolledUp)="onScrollUp()"
      (scrolled)="onScrollDown()"
    >
        <app-preview-auction
            *ngFor="let auction of auctions | slice:0: howManyAuctionsToShow; index as i; trackBy: trackItem"
            class="col-11"
            [auction]="auction"
            [isAdmin]="isAdmin"
          ></app-preview-auction>
    </div>
<!--    <p-paginator-->
<!--      *ngIf="auctions.length"-->
<!--      #paginator-->
<!--      styleClass="flex justify-content-end"-->
<!--      class="paginator"-->

<!--      [totalRecords]="auctions.length"-->
<!--    ></p-paginator>-->
    <ng-template #noAuction>
      <div
        class="w-full flex justify-content-center p-8 text-5xl line-height-2"
      >
        <span class="text-center"
          >Brak wyników wyszukiwania</span
        >
      </div>
    </ng-template>
  </div>
  <div class="surface-50 w-full flex justify-content-center align-items-center flex-grow-1"
       *ngIf="spinner"
  >
    <p-progressSpinner
      [strokeWidth]="'4'"
    ></p-progressSpinner>
  </div>

</div>
