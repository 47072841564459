<div id="sample-auctions" class="sample-container">
  <h2 class="sample-auction-title">Przykładowe oferty</h2>
  <p class="sample-auction-title-desc">
    Sprawdź, co jesteśmy w stanie Ci zaproponować
  </p>
</div>
<div *ngIf="!spinner" class="sample-list-auction">
  <ng-container *ngFor="let auction of auctions index as i; trackBy: trackItem">
    <app-preview-auction [auction]="auction"></app-preview-auction>
  </ng-container>
</div>
<div class="flex justify-content-center align-items-center" *ngIf="spinner">
  <p-progressSpinner
    [strokeWidth]="'4'"
  ></p-progressSpinner>
</div>
