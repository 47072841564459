import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { AuctionsListComponent } from "./auctions-list.component";
import { AuthGuardGuard } from "../auth-guard.guard";
import { ReactiveFormsModule } from "@angular/forms";
import { MultiSelectModule } from "primeng/multiselect";
import { DropdownModule } from "primeng/dropdown";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { PreviewAuctionModule } from "../preview-auction/preview-auction.module";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

const routes: Routes = [
  {
    path: '',
    component: AuctionsListComponent,
    canActivate: [AuthGuardGuard],
    title: 'Swiss-Car | Lista aukcji',
  },
];

@NgModule({
  declarations: [AuctionsListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    MultiSelectModule,
    DropdownModule,
    ProgressSpinnerModule,
    PreviewAuctionModule,
    ButtonModule,
    RippleModule,
    InfiniteScrollModule
  ]
})
export class AuctionsListModule { }
