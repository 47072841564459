<div class="flex justify-content-center align-items-center flex-column">
  <img width="300" height="300" alt="Zdjęcie poglądowe" [src]="'api/images/'+auctionData[0]?.primary_image"/>
  <table class="flex justify-content-center align-items-center flex-column mb-2">
    <tr>
      <th>Marka: </th>
      <td>{{auctionData[0]?.mark}}</td>
    </tr>
    <tr>
      <th>Model: </th>
      <td>{{auctionData[0]?.model}}</td>
    </tr>
    <tr>
      <th>Przebieg: </th>
      <td>{{auctionData[0]?.mileage}}</td>
    </tr>
    <tr>
      <th>Pierwsza rejestracja: </th>
      <td>{{auctionData[0]?.first_register}}</td>
    </tr>
    <tr>
      <th>Serwis: </th>
      <td>{{auctionData[0]?.serwis}}</td>
    </tr>
    <tr>
      <th>Ilość ofert: </th>
      <td>{{auctionData[0]?.bids.length}}</td>
    </tr>
    <tr>
      <th>Data zakończenia: </th>
      <td>{{auctionData[0]?.end_time | date:'dd/MM/yy, HH:mm' }}</td>
    </tr>
  </table>
</div>
