<div #myDiv >
  <p-card id="container" class="container section section--full">
    <p-table
      #dataTable
      [value]="auctions"
      [scrollable]="true"
      [rowHover]="true"
      [autoLayout]="true"
      [resizableColumns]="true"
      [columnResizeMode]="'expand'"
      [sortField]="'end_time'"
      [sortOrder]="1"
      [loading]="loadingTable"
      [globalFilterFields]="['user_id', 'created_on', 'last_login', 'activ']"
    >
      <ng-template pTemplate="caption">
        <div class="table-header flex justify-content-between">
          Lista wszystkich aukcji
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'auction_id'">
            Id aukcji
            <p-sortIcon [field]="'auction_id'"></p-sortIcon>
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'auctionID'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po ID'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('auctionID')"
                  ></button>
                  <!--              <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"-->
                  <!--                      (click)="acceptFilter()"-->
                  <!--              ></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>Link do aukcji</th>
          <th>
            Serwis
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'serwis'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po serwisie'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('serwis')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Marka
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'mark'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po marce'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('mark')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Model
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'model'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po modelu'"
                />
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('model')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th [pSortableColumn]="'mileage'">
            Przebieg
            <p-sortIcon [field]="'mileage'"></p-sortIcon>
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                Od
                <p-inputNumber
                  inputId="integeronly"
                  [formControlName]="'mileageStart'"
                  type="number"
                  pInputText
                  [placeholder]="'Przebieg od'"
                ></p-inputNumber>
                Do
                <p-inputNumber
                  inputId="integeronly"
                  [formControlName]="'mileageStart'"
                  type="number"
                  pInputText
                  [placeholder]="'Przebieg do'"
                ></p-inputNumber>
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('mileageStart', 'mileageEnd')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Rejestracja
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                Od
                <p-calendar
                  [formControlName]="'firstRegisterDateStart'"
                  [placeholder]="'Szukaj po pierwszej rejestacji'"
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                </p-calendar>
                Do
                <p-calendar
                  [formControlName]="'firstRegisterDateEnd'"
                  [placeholder]="'Szukaj po pierwszej rejestacji'"
                  [minDate]="
                    formFilter.controls['firstRegisterDateStart'].value
                  "
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                  ></p-calendar
                >
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="
                      resetFilters(
                        'firstRegisterDateStart',
                        'firstRegisterDateEnd'
                      )
                    "
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>VIN</th>
          <th>Kwota</th>
          <th>Prowadzi</th>
          <th [pSortableColumn]="'end_time'">
            Czas zakończenia
            <p-sortIcon [field]="'end_time'"></p-sortIcon>
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <p-selectButton
                  [formControlName]="'active'"
                  [options]="stateOptions"
                  optionLabel="label"
                  optionValue="value"
                  [multiple]="false"
                ></p-selectButton>
                Od
                <p-calendar
                  [formControlName]="'endTmeStart'"
                  [placeholder]="'Szukaj po dacie zakończenia'"
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                </p-calendar>
                Do
                <p-calendar
                  [formControlName]="'endTmeEnd'"
                  [placeholder]="'Szukaj po dacie zakończenia'"
                  [minDate]="formFilter.controls['endTmeStart'].value"
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                  </p-calendar>
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('endTmeStart', 'endTmeEnd')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>Opcje</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>
            <a [href]="'/aukcja/' + user.auction_id" target="_blank">{{
              user.auction_id
            }}</a>
          </td>
          <td>
            <a [href]="user.auction_webPage_adress" target="_blank">Link</a>
          </td>
          <td>{{ user.serwis }}</td>
          <td>{{ user.mark }}</td>
          <td [pTooltip]="user.model">{{ user.model }}</td>
          <td>{{ user.mileage | number: "2." }}</td>
          <td>{{ user.first_register === null || undefined ? 'Brak danych' : user.first_register | date: "dd/MM/yy" }}</td>
          <td [pTooltip]="user.VIN">
            {{
              user.VIN === null || undefined ? 'Brak danych' : user.VIN.length > 10 ? (user.VIN | slice: 0:10) + "..." : user.VIN
            }}
          </td>
          <td>
            {{ user.bids === null || undefined ? 'Brak danych' : user?.bids[0]?.price | number: "2."
            }}{{ user?.bids[0]?.price ? " CHF" : 0 }}
          </td>
          <td class="flex justify-content-around">
            {{ user?.bids[0]?.user_id || "Nikt" }}
            <button *ngIf="user?.bids[0]?.accounts" pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-search" (click)="openDialog(user?.bids[0]?.accounts)"></button>
          </td>
          <td>{{ user.end_time | date: "dd/MM/yy, HH:mm" }}</td>
          <td>
            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteSelectedAuction(user)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template [pTemplate]="'emptymessage'">
        <tr [class]="'empty-data'">
          <td colspan="10" class="h-30rem flex justify-content-center">
            Brak wyników do wyświetlenia
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="rowPerPage"
      [rows]="visibleRows"
      [showCurrentPageReport]="true"
      [currentPageReportTemplate]="
        'Showing {first} to {last} of {totalRecords} entries'
      "
      (onPageChange)="onPageChange($event)"></p-paginator>
  </p-card>
</div>
<p-confirmDialog header="Zatwierdź" [style]="{width: '50vw'}" [baseZIndex]="20000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
