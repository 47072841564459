<div class="flex justify-content-center align-items-center flex-column">
  <table class="flex justify-content-center align-items-center flex-column mb-2">
    <tr>
      <th>ID użytkownika</th>
      <td>{{userData[0]?.user_id}}</td>
    </tr>
    <tr>
      <th>Login</th>
      <td>{{userData[0]?.username}}</td>
    </tr>
    <tr>
      <th>Imię</th>
      <td>{{userData[0]?.name}}</td>
    </tr>
    <tr>
      <th>Nazwisko</th>
      <td>{{userData[0]?.surname}}</td>
    </tr>
    <tr>
      <th>Numer telefonu</th>
      <td>{{userData[0]?.phone}}</td>
    </tr>
    <tr>
      <th>Email</th>
      <td>{{userData[0]?.email}}</td>
    </tr>
    <tr>
      <th>Ostatnie logowanie</th>
      <td>{{userData[0]?.last_login | date:'dd/MM/yy, HH:mm' }}</td>
    </tr>
  </table>
  <p-button class="mb-2" (onClick)="this.show = !this.show" [disabled]="!userData[0]?.bids?.length" [label]="userData[0]?.bids?.length ? 'Pokaż wszystkie oferty' : 'Brak Ofert'" [badge]="userData[0]?.bids?.length || 0"></p-button>
  <p-table
    *ngIf="show"
    [value]="userData[0]?.bids"
  >
    <ng-template pTemplate="caption">
      <div class="table-header flex justify-content-between">
        Lista wszystkich ofert
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th [pSortableColumn]="'bids_id'">
          Id oferty
        </th>
        <th>
          Id użytkownika
        </th>
        <th>
          Kwota
        </th>
        <th>
          Id aukcji
        </th>
        <th [pSortableColumn]="'created_on'">
          Data oferty
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-bids>
      <tr>
        <td>{{ bids?.bids_id }}</td>
        <td>{{ bids?.user_id }}</td>
        <td>{{ bids?.price }}</td>
        <td>{{ bids?.auction_id }}</td>
        <td>{{ bids?.created_on | date: "dd/MM/yy, HH:mm" }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
