import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ComAPIService } from './API/com-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardGuard implements CanActivate {
  constructor(private router: Router) {}
  redirectURL: string
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // console.log('this.firstPage', new URL(window.location.href).pathname)

    if (!localStorage.getItem('user')) {
      this.redirectURL = new URL(window.location.href).pathname
      this.router.navigate(['/logowanie'], {queryParams: { returnURL: this.redirectURL }});
      // this.toastr.warning('Musisz się zalogować by mieć dostęp do tej strony');
      return false;
    } else {
      return true;
    }
    return false
  }
}
