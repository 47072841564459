<div class="col-12 md:col-9 m-auto">
  <p-table
    [value]="scripts"
    [responsiveLayout]="'scroll'"
    [loading]="loading"
    [autoLayout]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [rowHover]="true"
    [sortField]="'script_id'"
    [sortOrder]="1"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center">Id</th>
        <th class="text-center">Serwis</th>
        <th class="text-center">Ostatni start</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ostatnio dodane</th>
        <th class="text-center">Ręczne uruchomienie</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-script>
      <tr>
        <td class="text-center">{{ script.script_id }}</td>
        <td class="text-center">{{ script.name }}</td>
        <td class="text-center">
          {{ script.last_run | date: "dd/MM/yy, HH:mm" }}
        </td>
        <td class="text-center">
          {{ script.status ? "Ściąganie..." : "Czeka" }}
        </td>
        <td class="text-center">
          {{ script.last_add }} aukcji
        </td>
        <td class="text-center">
          <p-button
            [styleClass]="'w-full'"
            [loading]="script.status"
            [label]="script.status ? 'CZEKAJ' : 'START'"
            [disabled]="script.status || buttonsDisabled"
            (onClick)="runScript(script.name)"
          ></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-button
    [styleClass]="'w-full'"
    [label]="'Reset Skryptów'"
    (onClick)="resetScripts()"
  ></p-button>
</div>
