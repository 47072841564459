import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { ComAPIService } from '../API/com-api.service';
import { StorageManipulationService } from '../API/storage-manipulation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  showmenu = false;
  isLogin = false;
  href: string = '';
  router$: Subscription;
  isLogin$: Subscription;
  constructor(
    private router: Router,
    private eRef: ElementRef,
    public service: ComAPIService,
    private local: StorageManipulationService
  ) {
    this.isLogin$ = local.itemValue.subscribe((nextValue) => {
      this.isLogin = !!nextValue; // this will happen on every change
    });
  }

  ngOnInit() {
    this.router$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.href = event.url;
      }
    });
  }

  ngOnDestroy() {
    this.isLogin$.unsubscribe();
    this.router$.unsubscribe();
  }

  scrollButton() {
    document
      .getElementById('sample-auctions')!
      .scrollIntoView({ behavior: 'smooth' });
  }

  clickout(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) {
      console.log('click inside');
    } else {
      console.log('click outside');
    }
  }
  showMenu() {
    this.showmenu = !this.showmenu;
  }
}
