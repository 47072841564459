<div #myDiv>
  <p-card class="section section--full">
    <h2>Wygląd tabeli</h2>
    <p-multiSelect display="chip" styleClass="mb-5 max-w-16rem" [options]="columnsTable" [(ngModel)]="selectedOptions" defaultLabel="Zaznacz kolumny" optionLabel="name" optionValue="code"></p-multiSelect>
    <p-table
      [value]="auctions"
      [paginator]="true"
      [rowHover]="true"
      [scrollable]="true"
      [responsive]="true"
      [resizableColumns]="true"
      [showCurrentPageReport]="true"
      [sortField]="'end_time'"
      [sortOrder]="-1"
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="rowPerPage"
      [rows]="visibleRows"
      [loading]="loadingTable"
      [currentPageReportTemplate]="
        'Showing {first} to {last} of {totalRecords} entries'
      "
      [globalFilterFields]="['user_id', 'end_time']"
    >
      <ng-template pTemplate="caption">
        <div class="table-header flex justify-content-between">
          Lista aukcji w których złożyłeś ofertę
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="selectedOptions.includes('pa')">Podgląd aukcji</th>
          <th *ngIf="selectedOptions.includes('serwis')">
            Serwis
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'serwis'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po serwisie'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('serwis')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th *ngIf="selectedOptions.includes('marka')">
            Marka
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'mark'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po marce'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('mark')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th *ngIf="selectedOptions.includes('model')">
            Model
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'model'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po modelu'"
                />
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('model')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th *ngIf="selectedOptions.includes('przebieg')" [pSortableColumn]="'mileage'">
            Przebieg
            <p-sortIcon [field]="'mileage'"></p-sortIcon>
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                Od
                <p-inputNumber
                  inputId="integeronly"
                  [formControlName]="'mileageStart'"
                  type="number"
                  pInputText
                  [placeholder]="'Przebieg od'"
                ></p-inputNumber>
                Do
                <p-inputNumber
                  inputId="integeronly"
                  [formControlName]="'mileageStart'"
                  type="number"
                  pInputText
                  [placeholder]="'Przebieg do'"
                ></p-inputNumber>
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('mileageStart', 'mileageEnd')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th *ngIf="selectedOptions.includes('rejestracja')">
            Rejestracja
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                Od
                <p-calendar
                  [formControlName]="'firstRegisterDateStart'"
                  [placeholder]="'Szukaj po pierwszej rejestacji'"
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                </p-calendar>
                Do
                <p-calendar
                  [formControlName]="'firstRegisterDateEnd'"
                  [placeholder]="'Szukaj po pierwszej rejestacji'"
                  [minDate]="
                    formFilter.controls['firstRegisterDateStart'].value
                  "
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                  ></p-calendar
                >
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="
                      resetFilters(
                        'firstRegisterDateStart',
                        'firstRegisterDateEnd'
                      )
                    "
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th *ngIf="selectedOptions.includes('vin')">VIN</th>
          <th *ngIf="selectedOptions.includes('to')">Twoja oferta</th>
          <th *ngIf="selectedOptions.includes('miejsce')">Miejsce</th>
          <th *ngIf="selectedOptions.includes('koniec')"
              style='overflow: inherit'
              [pSortableColumn]="'end_time'">
            Koniec
            <p-sortIcon [field]="'end_time'"></p-sortIcon>
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <p-selectButton
                  [formControlName]="'active'"
                  [options]="stateOptions"
                  optionLabel="label"
                  optionValue="value"
                  [multiple]="true"
                ></p-selectButton>
                Od
                <p-calendar
                  [formControlName]="'endTmeStart'"
                  [placeholder]="'Szukaj po dacie zakończenia'"
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                </p-calendar>
                Do
                <p-calendar
                  [formControlName]="'endTmeEnd'"
                  [placeholder]="'Szukaj po dacie zakończenia'"
                  [minDate]="formFilter.controls['endTmeStart'].value"
                  selectionMode="single"
                  [dateFormat]="'dd/mm/yy'"
                  [readonlyInput]="true"
                  inputId="single"
                >
                  ></p-calendar
                >
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('endTmeStart', 'endTmeEnd')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-auction>
        <tr>
          <td *ngIf="selectedOptions.includes('pa')">
            <a [href]="'/aukcja/' + auction?.auction_id" target="_blank">
              <p-image
                [src]="'/api/images/' + auction.primary_image"
                alt="Image"
                width="150"
              ></p-image>
            </a>
          </td>
          <td *ngIf="selectedOptions.includes('serwis')">
            {{ auction?.serwis }}
          </td>
          <td *ngIf="selectedOptions.includes('marka')">
            {{ auction?.mark }}
          </td>
          <td *ngIf="selectedOptions.includes('model')">
            <span class="w-full white-space-normal">{{ auction?.model }}</span>
          </td>
          <td *ngIf="selectedOptions.includes('przebieg')">
            {{ !auction.mileage ? 0 : auction?.mileage | number: "2."}}
          </td>
          <td *ngIf="selectedOptions.includes('rejestracja')">
            {{ !auction.first_register ? 'Brak danych' :  auction?.first_register | date: "dd/MM/yy" }}
          </td>
          <td
            *ngIf="selectedOptions.includes('vin')"
            [pTooltip]="auction?.VIN"
          >
            <span class="w-full white-space-normal text-overflow-ellipsis text-center">{{
              auction.VIN ? auction?.VIN.length > 10
                ? (auction?.VIN | slice: 0:10) + "..."
                : auction?.VIN : 'Brak danych'
            }}</span>
          </td>
          <td *ngIf="selectedOptions.includes('to')">
            {{ auction?.myOffer | number: "2." }} CHF
          </td>
          <td
            *ngIf="selectedOptions.includes('miejsce')"
            [ngClass]="
              (auction?.end_time | isDateOlder)
                ? null
                : auction.place === 1
                ? 'bg-green-400'
                : 'bg-red-500'
            "
          >
            {{ auction?.place }} z {{ auction?._count?.bids }}
          </td>
          <td *ngIf="selectedOptions.includes('koniec')">
            {{ auction?.end_time | date: "dd/MM/yy, HH:mm" }}
          </td>
        </tr>
      </ng-template>
      <ng-template [pTemplate]="'emptymessage'">
        <tr [class]="'empty-data'">
          <td colspan="8" class="h-30rem flex justify-content-center text-center">
            Brak wyników do wyświetlenia
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>
