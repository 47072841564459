import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { DetailAuctionComponent } from "./detail-auction.component";
import { AuthGuardGuard } from "../auth-guard.guard";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { GALLERY_CONFIG, GalleryModule } from "ng-gallery";
import { CheckboxModule } from "primeng/checkbox";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TableModule } from "primeng/table";
import { MatInputModule } from "@angular/material/input";
import { LIGHTBOX_CONFIG, LightboxModule } from "ng-gallery/lightbox";
import { ToastrModule } from "ngx-toastr";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ButtonModule } from "primeng/button";

const routes: Routes = [
  {
    path: '',
    component: DetailAuctionComponent,
    canActivate: [AuthGuardGuard],
    title: 'Swiss-Car | Aukcja',
  },
];

@NgModule({
  declarations: [DetailAuctionComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ProgressSpinnerModule,
    GalleryModule,
    LightboxModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TableModule,
    MatInputModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      positionClass: "toast-top-right"
    }),
    InputNumberModule,
    InputTextareaModule,
    ButtonModule
  ],
  providers: [
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: false,
        imageSize: "contain",
        loop: true,
        thumbPosition: "bottom",
        loadingStrategy: "lazy",
        thumbWidth: "36",
        thumbHeight: "36",
        thumbView: "contain",
        thumbMode: "free"
      }
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: true
      }
    }
  ]
})
export class DetailAuctionModule { }
