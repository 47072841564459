import {
  ChangeDetectorRef,
  Component,
  ElementRef, Inject,
  OnInit, PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ComAPIService } from '../../API/com-api.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  debounceTime,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import { UserInfoComponent } from '../../shared/components/user-info/user-info.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {ConfirmationService, MessageService} from "primeng/api";
import {ToastrService} from "ngx-toastr";
import {isPlatformBrowser} from "@angular/common";
import { Table } from "primeng/table";

@Component({
  selector: 'app-all-auctions',
  templateUrl: './all-auctions.component.html',
  styleUrls: ['./all-auctions.component.scss'],
  providers: [MessageService, DialogService, ConfirmationService],
})
export class AllAuctionsComponent implements OnInit {
  auctions: any;
  totalRecords: number = 0;
  rowPerPage = [50, 100, 150, 300];
  visibleRows = 50;
  loadingTable = true;
  changeFilter$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('dataTable') public dataTable: Table;

  constructor(
    private service: ComAPIService,
    public dialogService: DialogService,
    private ref: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object

  ) {}

  dialog: DynamicDialogRef = new DynamicDialogRef();

  formFilter = new UntypedFormGroup({
    auctionID: new UntypedFormControl(''),
    serwis: new UntypedFormControl(''),
    mark: new UntypedFormControl(''),
    model: new UntypedFormControl(''),
    mileageStart: new UntypedFormControl(''),
    mileageEnd: new UntypedFormControl(''),
    gas: new UntypedFormControl(''),
    firstRegisterDateStart: new UntypedFormControl(),
    firstRegisterDateEnd: new UntypedFormControl(),
    VIN: new UntypedFormControl(''),
    auction_webPage_adress: new UntypedFormControl(''),
    endTmeStart: new UntypedFormControl(),
    endTmeEnd: new UntypedFormControl(),
    page: new UntypedFormControl(0),
    auctionPerPage: new UntypedFormControl(this.visibleRows),
    active: new UntypedFormControl(true),
  });

  stateOptions = [
    { label: 'Aktywne', value: true },
    { label: 'Zakończone', value: false },
  ];
  @ViewChild('myDiv') myDiv: ElementRef;

  show: boolean = true;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (
        new Date(this.formFilter.value?.endTmeStart) < new Date() &&
        this.formFilter.value?.active === true &&
        this.formFilter.value?.active === false
      ) {
        this.formFilter.value;
      } else if (
        new Date(this.formFilter.value?.endTmeStart) < new Date() &&
        this.formFilter.value?.active === true
      ) {
        this.formFilter.value.endTmeStart = new Date();
      }
      // console.log('this.formFilter?.value', this.formFilter?.value)
      this.loadingTable = true;
      this.ref.detectChanges();

      this.getAuctions(this.formFilter.value);
      this.changeFilter$ = this.formFilter.valueChanges
        .pipe(
          debounceTime(1000), takeUntil(this.destroy$),
          tap(value => {
            this.loadingTable = true;
            if (
              new Date(value?.endTmeStart) < new Date() &&
              value?.active === true &&
              value?.active === false
            ) {
              value;
            } else if (
              new Date(value?.endTmeStart) < new Date() &&
              value?.active === true
            ) {
              value.endTmeStart = new Date();
            }
            this.loadingTable = true;
            this.ref.detectChanges();
            this.getAuctions(value);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {});
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getAuctions(filters?: any) {
    this.service
      .getAllAuctions(filters)
      .pipe(
        tap((answer) => {
          this.auctions = answer.auctions;
          this.totalRecords = answer.totalAuctions;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.loadingTable = false;
        this.ref.detectChanges()
        }
      );
  }

  resetFilters(firstFilter: string, secondFilter?: string) {
    if (!firstFilter || !secondFilter) {
      return;
    }
    // @ts-ignore
    this.formFilter.controls[`${firstFilter}`].reset();
    if (secondFilter) {
      // @ts-ignore
      this.formFilter.controls[`${secondFilter}`].setValue(null);
    }
  }

  openDialog(accounts: any) {
    this.dialogService.open(UserInfoComponent, {
      data: {
        user_data: accounts,
      },
      header: 'Dane użytkownika',
    });
    this.ref.detectChanges();
  }

  onPageChange(event: any) {
    // console.log('pageChange', event)
    this.formFilter.get('auctionPerPage').setValue(event.rows);
    this.formFilter.get('page').setValue(event.first / event.rows);

    this.myDiv.nativeElement.scrollIntoView();
  }

  deleteAuction(auctionID: number, auction_ids: string) {
    this.service.deleteAuction(auctionID, auction_ids).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.toastr.success('Aukcja została usunięta');
    })
  }

  deleteSelectedAuction(auction: any) {
    console.log('auction', auction)
    this.confirmationService.confirm({
      message: `Jesteś pewień, że chcesz usunąć tę aukcję "${auction.mark} ${auction.model}"`,
      header: 'Zatwierdź',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.auctions = this.auctions.filter((val: any) => val !== auction);
        this.deleteAuction(auction.auction_id, auction.auction_ids);
        this.ref.detectChanges();
      },
      reject: () => { console.log('Do nothing'), this.ref.detectChanges();}
    });
    this.ref.detectChanges();

  }
}
